<template>
  <div>
    <v-form>
      <p class="mb-3">Select the appropriate word to complete the Lab Safety Agreement.</p>

      <p class="mb-2" style="font-size: 1.25rem; font-weight: bold">I will...</p>

      <ul class="mb-5">
        <li class="my-2">
          wear safety <b>&nbsp;[A]</b>, not safety glasses, and lab aprons at all times.
        </li>
        <li class="my-2">wear prescription eye glasses underneath goggles.</li>
        <li class="my-2">
          wear clothing providing <b>&nbsp;[B]&nbsp;</b> protection. Shorts, Capri pants, sandals,
          slip on shoes, minis, and bare stomach, shoulders, or backs are not allowed in the labs.
        </li>
        <li class="my-2">
          conduct myself in a mature fashion. Excessive noise or <b>&nbsp;[C]&nbsp;</b> behaviors
          are NOT permitted.
        </li>
        <li class="my-2">
          note the exact location of all <b>&nbsp;[D]&nbsp;</b> and learn its proper use.
        </li>
        <li class="my-2">
          perform reactions using (or evolving) noxious or highly combustible chemicals in a
          <b>&nbsp;[E]</b>.
        </li>
        <li class="my-2">report and <b>&nbsp;[F]&nbsp;</b> all spills immediately.</li>
        <li class="my-2">
          <b>[G]&nbsp;</b> from eating, drinking, smoking, or chewing gum in lab.
        </li>
        <li class="my-2"><b>[H]&nbsp;</b> of any cracked or broken glassware.</li>
        <li class="my-2">
          extinguish all flames or ignition sources when using <b>&nbsp;[I]&nbsp;</b> and/or
          volatile chemicals.
        </li>
        <li class="my-2">
          place all left‑over chemicals in the appropriate collection bottles in the hood. Do NOT
          dump chemicals into the <b>&nbsp;[J]</b>.
        </li>
        <li class="my-2">
          perform only supervised and authorized experiments in lab. An <b>&nbsp;[K]&nbsp;</b> must
          be present at all times and all procedures must be followed.
        </li>
        <li class="my-2">
          use tongs or protective gloves to handle <b>&nbsp;[L]&nbsp;</b> objects.
        </li>
        <li class="my-2">
          add concentrated <b>&nbsp;[M]&nbsp;</b> slowly to water while stirring when diluting –
          NEVER the reverse!
        </li>
        <li class="my-2">
          use <b>&nbsp;[N]&nbsp;</b> procedures and precautions when inserting glass tubing into a
          rubber stopper.
        </li>
        <li class="my-2">
          properly label all chemical containers and <b>&nbsp;[O]&nbsp;</b> labels before using
          chemicals.
        </li>
        <li class="my-2">
          store all book bags, purses, coats, etc., in <b>&nbsp;[P]&nbsp;</b> areas to avoid
          clutter.
        </li>
        <li class="my-2">
          <b>[Q]&nbsp;</b> my workspace and wash hands frequently during lab and thoroughly before
          leaving lab.
        </li>
        <li class="my-2">
          think before acting and use <b>&nbsp;[R]&nbsp;</b> judgement and care in the lab.
        </li>
        <li class="my-2">
          <b>[S]&nbsp;</b> report all injuries to the instructor no matter how small the injury
          appears.
        </li>
      </ul>

      <p v-for="question in questions" :key="question.number">
        {{ question.part }}
        <v-select
          v-model="inputs[question.input]"
          class="d-sm-inline-block ml-3 mr-3"
          :items="options"
          item-text="text"
          item-value="value"
          style="width: 293px"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI1LCD_SafetyQuiz_Q1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
        inputC: null,
        inputD: null,
        inputE: null,
        inputF: null,
        inputG: null,
        inputH: null,
        inputI: null,
        inputJ: null,
        inputK: null,
        inputL: null,
        inputM: null,
        inputN: null,
        inputO: null,
        inputP: null,
        inputQ: null,
        inputR: null,
        inputS: null,
      },
      questions: [
        {part: '[A]:', input: 'inputA', number: '1'},
        {part: '[B]:', input: 'inputB', number: '2'},
        {part: '[C]:', input: 'inputC', number: '3'},
        {part: '[D]:', input: 'inputD', number: '4'},
        {part: '[E]:', input: 'inputE', number: '5'},
        {part: '[F]:', input: 'inputF', number: '6'},
        {part: '[G]:', input: 'inputG', number: '7'},
        {part: '[H]:', input: 'inputH', number: '8'},
        {part: '[I]:', input: 'inputI', number: '9'},
        {part: '[J]:', input: 'inputJ', number: '10'},
        {part: '[K]:', input: 'inputK', number: '11'},
        {part: '[L]:', input: 'inputL', number: '12'},
        {part: '[M]:', input: 'inputM', number: '13'},
        {part: '[N]:', input: 'inputN', number: '14'},
        {part: '[O]:', input: 'inputO', number: '15'},
        {part: '[P]:', input: 'inputP', number: '16'},
        {part: '[Q]:', input: 'inputQ', number: '17'},
        {part: '[R]:', input: 'inputR', number: '18'},
        {part: '[S]:', input: 'inputS', number: '19'},
      ],
      options: [
        {text: 'acid', value: 'acid'},
        {text: 'clean', value: 'clean'},
        {text: 'clean up', value: 'cleanUp'},
        {text: 'correct', value: 'correct'},
        {text: 'designated', value: 'designated'},
        {text: 'dispose', value: 'dispose'},
        {text: 'disruptive', value: 'disruptive'},
        {text: 'flammable', value: 'flammable'},
        {text: 'fume hood', value: 'fumeHood'},
        {text: 'goggles', value: 'goggles'},
        {text: 'good', value: 'good'},
        {text: 'hot', value: 'hot'},
        {text: 'immediately', value: 'immediately'},
        {text: 'instructor', value: 'instructor'},
        {text: 'maximum', value: 'maximum'},
        {text: 'read', value: 'read'},
        {text: 'refrain', value: 'refrain'},
        {text: 'sink or trash', value: 'sinkOrTrash'},
        {text: 'safety equipment', value: 'safetyEquipment'},
      ],
    };
  },
};
</script>
